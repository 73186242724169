import './loader.css';

export const Loader = {
  tpl: () => {
    return `
      <div class="c_loader">
        <div class="c_loader-bounce1"></div>
        <div class="c_loader-bounce2"></div>
        <div class="c_loader-bounce3"></div>
      </div>`;
  }
};
