import '../utils/_localStorageEvent';

import {ApiConnector} from './api';
import {Loader} from '../components/loader/loader';
import {Header} from '../components/header/header';
import {Footer} from '../components/footer/footer';
import {FormConnector} from '../components/form/form';

const preLoginPageModule = () => import(/* webpackChunkName: 'pages/preLogin' */'../pages/preLogin/preLogin');
const loginPageModule = () => import(/* webpackChunkName: 'pages/login' */'../pages/login/login');
const accountPageModule = () => import(/* webpackChunkName: 'pages/account' */'../pages/account/account');
const createAccountPageModule = () => import(/* webpackChunkName: 'pages/createAccount' */'../pages/createAccount/createAccount');
const forgotPwdPageModule = () => import(/* webpackChunkName: 'pages/forgotPwd' */'../pages/forgotPwd/forgotPwd');
const forgotPwdCodePageModule = () => import(/* webpackChunkName: 'pages/forgotPwdCode' */'../pages/forgotPwdCode/forgotPwdCode');
const forgotPwdUpdatePageModule = () => import(/* webpackChunkName: 'pages/forgotPwdUpdate' */'../pages/forgotPwdUpdate/forgotPwdUpdate');

const popinModule = () => import(/* webpackChunkName: 'components/popin' */'../components/popin/popin');
const bookingModule = () => import(/* webpackChunkName: 'components/booking' */'../components/booking/booking');

const connector_open_class = 'connector-open';
const connector_connected_class = 'connector-connected';
const loading_class = 'loading';

window.Loader = Loader;
window.Header = Header;
window.Footer = Footer;

window.Connector = {
  tracking: typeof Tracking === 'object' ? Tracking.dataLayerPush : () => { },
  isLoading: true,
  api: ApiConnector,

  init: (options) => {
    Connector.el = document.createElement('div');
    Connector.options = options;
    Connector.el.id = 'connector';
    Connector.el.classList.add(loading_class);
    Connector.el.innerHTML = Loader.tpl();
    $('body')[0].append(Connector.el);

    setInterval(() => {
      if (Connector.isLoading) {
        Connector.el.classList.add(loading_class);
      } else {
        Connector.el.classList.remove(loading_class);
      }
    }, 50);

    // init Firebase
    if (firebase.apps.length === 0) {
      firebase.initializeApp(GLOBAL_SCRIPTS.FIREBASE_CONF);
    }

    // detect Firebase auth changes (if a user login or logout from another tab)
    firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        Connector.forceLogin(user);
      } else if (localStorage.getItem('users') !== null) {
        Connector.api.user.logout();
      }
    });
  },

  open: (page = '', additionnal_page_datas = {}) => {
    $('html')[0].classList.add(connector_open_class);
    $('body')[0].classList.remove('navDisplayed');

    // if wordings not set = First initialisation
    if (typeof Connector.wordings === 'undefined') {
      // Get Wordings
      ApiConnector.wordings.get((wordings) => {
        // Set wordings to global object
        Connector.wordings = wordings;

        // Display default page and set isLoading to false
        Connector.open(localStorage.getItem('users') !== null ? 'account' : 'preLogin');
        Connector.isLoading = false;
      });
    }

    if (page !== '') {
      Connector.el.querySelectorAll('.c_page').forEach((page) => {
        page.remove();
      });
    }

    let pagePromise;

    switch (page) {
      case 'preLogin':
        pagePromise = preLoginPageModule().then(({PreLoginPage}) => {
          return Promise.resolve()
            .then(() => {
              Connector.el.innerHTML += PreLoginPage.tpl();
            })
            .then(() =>
              PreLoginPage.init()
            )
            .then(() => {
              Connector.el.querySelector('#username').focus();
            });
        });

        break;
      case 'login':
        pagePromise = loginPageModule().then(({LoginPage}) => {
          return Promise.resolve()
            .then(() => {
              Connector.el.innerHTML += LoginPage.tpl();
            })
            .then(() =>
              LoginPage.init()
            )
            .then(() =>
              Connector.el.querySelector('#password').focus()
            );
        });

        break;
      case 'forgotPwd':
        pagePromise = forgotPwdPageModule().then(({ForgotPwdPage}) => {
          return Promise.resolve()
            .then(() => {
              Connector.el.innerHTML += ForgotPwdPage.tpl();
            })
            .then(() =>
              ForgotPwdPage.init()
            );
        });

        break;
      case 'forgotPwdCode':
        pagePromise = forgotPwdCodePageModule().then(({ForgotPwdCodePage}) => {
          return Promise.resolve()
            .then(() => {
              Connector.el.innerHTML += ForgotPwdCodePage.tpl(additionnal_page_datas);
            })
            .then(() =>
              ForgotPwdCodePage.init()
            );
        });

        break;
      case 'forgotPwdUpdate':
        pagePromise = forgotPwdUpdatePageModule().then(({ForgotPwdUpdatePage}) => {
          return Promise.resolve()
            .then(() => {
              Connector.el.innerHTML += ForgotPwdUpdatePage.tpl(additionnal_page_datas);
            })
            .then(() =>
              ForgotPwdUpdatePage.init()
            );
        });

        break;
      case 'account':
        pagePromise = accountPageModule().then(({AccountPage}) => {
          return bookingModule().then(({Booking}) => {
            window.Booking = Booking;

            const bookingHtml = Booking.tpl();

            return Promise.resolve()
              .then(() => {
                Connector.el.innerHTML += AccountPage.tpl(bookingHtml);
              })
              .then(() => AccountPage.init())
              .then(() => Booking.init());
          });
        });

        break;
      case 'createAccount':
        pagePromise = createAccountPageModule().then(({CreateAccountPage}) => {
          const popinParameter = CreateAccountPage.getPopinParameter();

          return popinModule().then(({Popin}) => {
            window.Popin = Popin;

            const popinHtml = Popin.tpl(popinParameter);
            Connector.el.innerHTML += CreateAccountPage.tpl(popinHtml);

            return Promise.resolve()
              .then(() => CreateAccountPage.init())
              .then(() => Popin.init());
          });
        });

        break;
      default:
        pagePromise = Promise.resolve();
    }

    pagePromise.then(() => {
      Header.init();
      Footer.init();
      FormConnector.init();
    }).catch(error => console.error('Erreur lors de l\'initialisation de la page :', error));

    if (page === '') {
      if (typeof Connector.options.onOpen === 'function') {
        Connector.options.onOpen();
      }

      if (typeof Connector.options.onToggle === 'function') {
        Connector.options.onToggle();
      }
    } else {
      Connector.currentPage = page;
    }

    // calculate top depending on height of conenctor pages and annoucementBar
    Connector.el.style = '';

    const announcementBar = document.querySelector('.js-announcementBar');
    const hasAnnouncementBar = announcementBar !== null;

    if ((DEVICE.isMobile && Connector.el.querySelector('.c_container') !== null && Connector.el.querySelector('.c_container').offsetHeight > window.innerHeight) || (!DEVICE.isMobile && announcementBar)) {
      const top = document.querySelector('.headerMob').clientHeight + (hasAnnouncementBar ? announcementBar.clientHeight : 0);
      Connector.el.style.top = top + 'px';
      Connector.el.style.maxHeight = 'calc(100% - ' + top + 'px)';
    }

    setTimeout(() => {
      if (document.querySelector('.js-priceTooltip-popin') !== null) {
        document.querySelector('.js-priceTooltip-popin').style.display = 'none';
      }
    }, 1000);
  },

  currentPage: '',

  getWording: (wordingKey) => {
    // Si wording 404 not found peu importe la raison : on affiche la clé de wording non traduite
    return typeof Connector.wordings === 'object' && Object.prototype.hasOwnProperty.call(Connector.wordings, wordingKey) ? Connector.wordings[wordingKey] : wordingKey;
  },

  close: () => {
    $('html')[0].classList.remove(connector_open_class);

    if (typeof Connector.options.onClose === 'function') {
      Connector.options.onClose();
    }

    if (typeof Connector.options.onToggle === 'function') {
      Connector.options.onToggle();
    }
  },

  isOpen: () => {
    return $('html')[0].classList.contains(connector_open_class);
  },

  isConnected: () => {
    return $('html')[0].classList.contains(connector_connected_class);
  },

  forceLogin: (user) => {
    user.getIdToken().then((token) => {
      Connector.api.user.get(user.email, user.uid, token).then((userInfos) => {
        Connector.userInfos = userInfos;
        Connector.newUser = false;
        Connector.isLoading = false;
        $('html')[0].classList.add('connector-connected');

        if (['login', 'forgotPwdUpdate', 'createAccount'].includes(Connector.currentPage)) {
          Connector.open('account');
        }
      }).catch((error) => {
        Connector.isLoading = false;

        if (typeof e != 'undefined') {
          e.target.querySelector('p[data-show-if="' + (error === 403 ? 'loginFormSubmitMismatch' : 'formSubmitError') + '"]').style.display = 'block';
        }

        if (error === 403) {
          Connector.tracking({
            clickCode: 'ConnexionError',
            gaCategory: 'CP connexion error',
            gaAction: 'ConnexionError',
            gaLabel: Connector.getWording('errorEmailOrPassword'),
            event: 'clickEvent'
          });
        }
      });
    }).catch(() => {
      if (typeof e != 'undefined') e.detail.target.querySelector('p[data-show-if="formSubmitError"]').style.display = 'block';
      Connector.isLoading = false;
    });
  },

  getLastBooking: (bookings) => {
    const bookingIds = Object.keys(bookings);
    let lastBooking = false;

    if (bookingIds.length) {
      const confirmedBookings = [];
      const confirmedBookingsToCome = [];
      const sortBookingsFn = (inversed = false) => {
        return (previousBooking, nextBooking) => {
          return inversed ? new Date(previousBooking.product.end) - new Date(nextBooking.product.end) : new Date(nextBooking.product.end) - new Date(previousBooking.product.end);
        };
      };

      bookingIds.forEach((bookingId) => {
        const booking = bookings[bookingId];

        if (booking.status === 'Confirm') {
          confirmedBookings.push(booking);

          if (Date.parse(booking.product.end) > Date.now()) {
            confirmedBookingsToCome.push(booking);
          }
        }
      });

      confirmedBookings.sort(sortBookingsFn());
      confirmedBookingsToCome.sort(sortBookingsFn(true));

      if (confirmedBookingsToCome.length) {
        lastBooking = confirmedBookingsToCome[0];
      } else if (confirmedBookings.length) {
        lastBooking = confirmedBookings[0];
      }
    }

    return lastBooking;
  }
};