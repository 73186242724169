import './footer.css';

export const Footer = {
  init: () => {
    // Tracking on click on footer
    $('.c_footer li, .c_footer a').on('click', (e) => {
      Connector.tracking({
        event: 'clickEvent',
        gaCategory: 'Connector',
        gaAction: e.target.closest('li').getAttribute('data-tracking-label'),
        gaLabel: 'Click'
      });
    });
  },

  tpl: (connected) => {
    return `
      <div class="c_footer">
        <ul>
          <li data-tracking-label="Need help">
            <a href="${GLOBAL_SCRIPTS.BASELINK}/faq" target="_blank">${Connector.getWording('faqLabel')}</a>
          </li>
          ${connected ? `
          <li class="c_account-logout" data-tracking-label="Logout">${Connector.getWording('footerConnectedProfilItem5')}</li>
          ` : ''}
        </ul>
      </div>`;
  }
};
