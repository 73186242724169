import './header.css';



export const Header = {
  init: () => {
    // click action on icon close
    $('.c_header-close').on('click', (e) => {
      e.preventDefault();

      Connector.close();
      Connector.tracking({
        gaCategory: PAGE_SCRIPTS.page_template,
        gaAction: 'Connexion popin',
        gaLabel: 'close',
        event: 'clickEvent'
      });
    });

    // click action on arrow back
    $('.js-backLogin').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const $page = Connector.el.querySelector('.c_page');

      // TRACKING
      Connector.tracking({
        event: 'clickEvent',
        gaCategory: 'Connector',
        gaAction: 'Return',
        gaLabel: 'Return Icon Click'
      });

      if ($page.classList.contains('c_createAccount') || $page.classList.contains('c_login')) {
        Connector.open('preLogin');
      }

      if ($page.classList.contains('c_pwdCodeSent') || $page.classList.contains('c_pwdUpdate')) {
        Connector.open('forgotPwd');
      }

      if ($page.classList.contains('c_forgotPwd')) {
        Connector.open('login');
      }
    });
  },

  tpl: (label) => {
    return `
      <div class="c_header">
        <span class="c_icon c_header-arrow-back js-backLogin"></span>
        <span class="c_header-label">${label}</span>
        <span class="c_icon c_header-close"></span>
      </div>`;
  }
};
