import './form.css';

export const FormConnector = {
  init: () => {
    window.FormConnector = FormConnector;

    // Form validation on change/blur
    $('.c_form input').on('blur keyup', (e) => {
      const currentTarget = e.target;
      const isRequired = !currentTarget.closest('.c_form-group').classList.contains('no-required');

      if (isRequired) {
        FormConnector.validateInput(currentTarget);
      }
    });

    // Form validation on form submit
    $('.c_form').on('submit', (e) => {
      let form_is_valid = true;

      e.preventDefault();

      e.target.querySelectorAll('.c_form-group').forEach(group => {
        if (!group.matches('.is-valid')) {
          form_is_valid = false;
          FormConnector.validateInput(group.querySelector('input'));
        }
      });

      if (form_is_valid) {
        Connector.el.dispatchEvent(new CustomEvent('successFormValidation', {
          detail: {
            target: e.target
          }
        }));

        $(e.currentTarget).trigger('successFormValidation');
      }
    });

    // Field reset on click on cross
    $('.c_form-group.is-error .c_form-icon:not(.c_form-showPass)').on('click', (e) => {
      [].slice.call(e.target.parentNode.children).filter(child => child.matches('input'))[0].value = '';
      e.target.closest('.c_form-group').classList.remove('is-valid', 'is-error', 'is-empty');
    });

    // Show/hide password
    $('.c_form-showPass').on('click', (e) => {
      const input = [].slice.call(e.target.parentNode.children).filter(child => child.matches('input'))[0];

      input.type = input.type === 'text' ? 'password' : 'text';
    });
  },

  enabledDisabledButton: () => {
    const buttonContinue = Connector.el.querySelector('.c_createAccount .c_create') || Connector.el.querySelector('.c_preLogin .button-continue') || Connector.el.querySelector('.c_forgotPwd .button--pinkWhite');

    if (buttonContinue !== null) {
      buttonContinue.classList.remove('disabled');

      Connector.el.querySelectorAll('.c_form-group:not(.hidden):not(.no-required)').forEach((groupInput) => {
        if (!groupInput.matches('.is-valid')) {
          buttonContinue.classList.add('disabled');
        }
      });
    }
  },

  passwordValidator: (passwordValue) => {
    if (Connector.el.querySelector('#password') !== null && Connector.el.querySelector('.c_login') === null) {
      let nbCriteriaValid = 0;

      if (passwordValue.length >= 6) {
        Connector.el.querySelector('.c_pswd_nbChar').classList.add('passed');
        nbCriteriaValid++;
      } else {
        Connector.el.querySelector('.c_pswd_nbChar').classList.remove('passed');
      }

      if (/[A-Z]/.test(passwordValue)) {
        Connector.el.querySelector('.c_pswd_upper').classList.add('passed');
        nbCriteriaValid++;
      } else {
        Connector.el.querySelector('.c_pswd_upper').classList.remove('passed');
      }

      if (/[0-9]/.test(passwordValue)) {
        Connector.el.querySelector('.c_pswd_numbers').classList.add('passed');
        nbCriteriaValid++;
      } else {
        Connector.el.querySelector('.c_pswd_numbers').classList.remove('passed');
      }

      if (nbCriteriaValid >= 3) {
        Connector.el.querySelector('#password').closest('.c_form-group').classList.add('is-valid');
      } else {
        nbCriteriaValid = 0;
        Connector.el.querySelector('#password').closest('.c_form-group').classList.remove('is-valid');
      }
    }
  },

  validateInput: (input) => {
    const isEmpty = input.value === '';
    const group = input.closest('.c_form-group');

    let isValid = !isEmpty;

    // Hide all global form error messages (not related to input)
    group.querySelectorAll('p[data-show-if]:not([data-show-if="error"]):not([data-show-if="empty"])').forEach((globalFormErrorMsgElement) => {
      globalFormErrorMsgElement.style.display = 'none';
    });

    if (!isEmpty && input.type === 'email') {
      // eslint-disable-next-line no-control-regex
      isValid = /(?!.*\.{2})^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i.test(input.value);
    }

    if (input.id === 'password') {
      FormConnector.passwordValidator(input.value);
      isValid = !isEmpty && !Connector.el.querySelector('.c_validatePassword span:not(.passed)');
    }

    if (Connector.el.querySelector('p[data-show-if="alreadyexist"]') !== null) {
      Connector.el.querySelector('p[data-show-if="alreadyexist"]').style.display = 'none';
    }

    if (!isEmpty && input.getAttribute('pattern') !== null) {
      isValid = new RegExp(input.getAttribute('pattern')).test(input.value);
    }

    if (!group.querySelectorAll('.c_form-checkboxContainer').length) {
      group.classList.toggle('is-valid', isValid && !isEmpty);
      group.classList.toggle('is-error', isEmpty || !isValid);
      group.classList.toggle('is-empty', isEmpty);
    }

    FormConnector.enabledDisabledButton();
  }
};