/**
 * LocalStorage - SetItem override to add instant firing
 */
const origSetItem = window.localStorage.setItem;

window.localStorage.setItem = function setItem(key, value) {
  // Retrieve old value before we store the new one
  let oldValue = window.localStorage.getItem(key);

  // Store in LocalStorage
  const result = origSetItem.apply(this, arguments);

  // Manually fire a "storage" event so this window is alerted. On its own,
  // localStorage.setItem() only fires a "storage" event for other tabs.
  const e = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key,
    oldValue,
    newValue: value,
    url: window.location.href
  });

  window.dispatchEvent(e);

  return result;
};

/**
 * LocalStorage - RemoveItem override to add instant firing
 */
const origRemoveItem = localStorage.removeItem;

window.localStorage.removeItem = function (key) {
  // Retrieve old value before we store the new one
  let oldValue = window.localStorage.getItem(key);

  // Store in LocalStorage
  const result = origRemoveItem.apply(this, arguments);

  // Manually fire a "storage" event so this window is alerted. On its own,
  // localStorage.setItem() only fires a "storage" event for other tabs.
  const e = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key,
    oldValue,
    newValue: null,
    url: window.location.href
  });

  window.dispatchEvent(e);

  return result;
};
