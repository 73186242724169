let controller;

const globalFetchHandler = (response) => {
  return response.json().then((responseDatas) => {
    if (!response.ok || (typeof responseDatas.status !== 'undefined' && responseDatas.status !== 'success')) {
      if (response.status === 401) {
        firebase.auth().signOut();
      }
    }

    return responseDatas;
  }).catch((responseDatas) => {
    return Promise.reject(responseDatas);
  });
};

export const ApiConnector = {
  bookings: {
    get: (bookingId) => {
      controller = new AbortController();
      return fetch(`${GLOBAL_SCRIPTS.URL_API_MY}booking/${bookingId}`, {
        signal: controller.signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': `Bearer ${Connector.userInfos.token}`
        }
      }).then(globalFetchHandler).catch((error) => {
        return Promise.reject(error);
      }).then((bookingDatas) => {
        return bookingDatas;
      });
    },
    getAll: () => {
      controller = new AbortController();
      return fetch(`${GLOBAL_SCRIPTS.URL_API_MY}bookings`, {
        signal: controller.signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': `Bearer ${Connector.userInfos.token}`
        }
      }).then(globalFetchHandler).catch((bookingsDatas) => {
        return bookingsDatas.results;
      }).then((bookingsDatas) => {
        Connector.isLoading = false;
        return bookingsDatas.results;
      });
    }
  },

  wordings: {
    get: (successCb) => {
      return fetch(`${GLOBAL_SCRIPTS.URL_API_WORDINGS}?language=${GLOBAL_SCRIPTS.LANGUAGE_CODE}&pages=CONNECTOR&univers=cpe`, {
        method: 'GET'
      }).then(globalFetchHandler).then((responseDatas) => {
        successCb(responseDatas.results.CONNECTOR);
      }).catch((error) => {
        successCb({});
      });
    }
  },

  user: {
    get: (email, password, token) => {
      return fetch(`${GLOBAL_SCRIPTS.URL_API_MY}user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          language: GLOBAL_SCRIPTS.LANGUAGE_CODE,
          market: GLOBAL_SCRIPTS.MARKET_CODE,
          login: email,
          firebaseUid: password
        })
      }).then(globalFetchHandler).catch((error) => {
        localStorage.removeItem('users');
        return Promise.reject(error);
      }).then((userInfos) => {
        localStorage.setItem('users', JSON.stringify(userInfos));
        return userInfos;
      });
    },

    sendPassword: (email) => {
      Connector.isLoading = true;
      Connector.user = {email: email};
      return fetch(`${GLOBAL_SCRIPTS.URL_API_MY}sendpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify({
          email: email,
          language: GLOBAL_SCRIPTS.LANGUAGE_CODE.toUpperCase(),
          testLoginOnly: true,
          sendTwofactorskey: true
        })
      }).then(globalFetchHandler).catch((error) => {
        return Promise.reject(error);
      }).then((datas) => {
        Connector.isLoading = false;
        Connector.newUser = true;

        if (datas.messages.length && datas.messages[0].code === 'ERRPARTYNOTFOUND_SENDPWD') {
          Connector.open('createAccount');
        } else {
          Connector.open('login');
          Connector.el.querySelector('#password').focus();
        }
        return datas;
      });
    },

    forgotPwd: (email) => {
      return fetch(`${GLOBAL_SCRIPTS.URL_API_MY}sendpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify({
          language: GLOBAL_SCRIPTS.LANGUAGE_CODE,
          email: email,
          sendTwofactorskey: true
        })
      }).then(globalFetchHandler).catch((error) => {
        return Promise.reject(error);
      }).then((userInfosLight) => {
        return userInfosLight;
      });
    },

    forgotPwdCode: (email, code) => {
      return fetch(`${GLOBAL_SCRIPTS.URL_API_MY}twofactorauth?language=${GLOBAL_SCRIPTS.LANGUAGE_CODE}&market=${GLOBAL_SCRIPTS.MARKET_CODE}&twoKeyFactorCode=${code}&email=${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(globalFetchHandler).catch((error) => {
        return Promise.reject(error);
      }).then((userInfosLight) => {
        return userInfosLight;
      });
    },

    forgotPwdUpdate: (password, hashlink) => {
      return fetch(`${GLOBAL_SCRIPTS.URL_API_MY}updatepassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify({
          password: password,
          hashlink: hashlink
        })
      }).then(globalFetchHandler).catch((error) => {
        return Promise.reject(error);
      }).then((userInfosLight) => {
        return userInfosLight;
      });
    },

    getAccountDatas: () => {
      Connector.isLoading = false;
      return fetch(`${GLOBAL_SCRIPTS.URL_API_MY}createaccountdirect?language=` + (GLOBAL_SCRIPTS.LANGUAGE_CODE.toUpperCase() === 'PL' ? 'en' : GLOBAL_SCRIPTS.LANGUAGE_CODE), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(globalFetchHandler).catch((error) => {
        return Promise.reject(error);
      }).then((formulaireInput) => {
        return formulaireInput;
      });
    },

    createAccount: () => {
      let paramsRequired = {
        country: Connector.el.querySelector(DEVICE.isDesktop ? '.js-customSelectValueInput' : '#custom-select option:checked').value.toUpperCase(),
        language: GLOBAL_SCRIPTS.MARKET_CODE.toUpperCase(),
        email: Connector.el.querySelector('#email').value,
        password: Connector.el.querySelector('#password').value,
        civility: Connector.el.querySelector('.civility-choice.selected').dataset.value,
        initials: Connector.el.querySelector('#initials').value,
        surnamePrefix: Connector.el.querySelector('#surnamePrefix').value,
        street2: Connector.el.querySelector('#street2').value,
        lastName: Connector.el.querySelector('#lastName').value,
        firstName: Connector.el.querySelector('#firstName').value,
        building: Connector.el.querySelector('#numberstreet').value,
        street1: Connector.el.querySelector('#street1').value,
        zipCode: Connector.el.querySelector('#zipCode').value,
        city: Connector.el.querySelector('#city').value,
        phone: Connector.el.querySelector('#phone').value,
        mobile: '',
        optInEmail: Connector.el.querySelector('#optInEmail').checked ? 1 : 0,
        optInMail: Connector.el.querySelector('#optInMail').checked ? 1 : 0,
        optInSms: Connector.el.querySelector('#optInSms').checked ? 1 : 0,
        optInCall: Connector.el.querySelector('#optInCall').checked ? 1 : 0
      };

      Connector.isLoading = true;
      return fetch(`${GLOBAL_SCRIPTS.URL_API_MY}createaccountdirect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(paramsRequired)
      }).then(globalFetchHandler).catch((error) => {
        return Promise.reject(error);
      }).then((datas) => {
        if (datas.messages.length > 0) {
          if (datas.messages[0].code === 'WRNPARTYEXISTS' || datas.messages[0].code === 'ERRPARTYEXISTS') {
            Connector.el.querySelector('p[data-show-if="alreadyexist"]').style.display = 'block';
            Connector.el.querySelector('#email').closest('.c_form-group').classList.remove('is-valid');
            Connector.el.querySelector('#email').closest('.c_form-group').classList.add('is-exist');
          } else {
            Connector.el.querySelector('.c_popinLabel').innerHTML = Connector.getWording(datas.messages[0].code);
            Connector.el.querySelector('.c_popin').classList.add('visible');
          }
        } else {
          Connector.newUser = true;
          firebase.auth().signInWithEmailAndPassword(datas.results.customer.email, Connector.el.querySelector('#password').value);
        }

        return datas;
      });
    },

    logout: () => {
      const logout_cb = () => {
        localStorage.removeItem('users');
        delete Connector.userInfos;
        document.querySelector('html').classList.remove('connector-connected');
      };

      Connector.isLoading = true;

      if (controller) {
        controller.abort();
      }

      return fetch(`${GLOBAL_SCRIPTS.URL_API_MY}disconnect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': `Bearer ${Connector.userInfos.token}`
        }
      }).finally(() => {
        logout_cb();
        Connector.open('preLogin');
        Connector.isLoading = false;
        firebase.auth().signOut();
      });
    }
  }
};
